import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.donationsApiBaseUrl;

export default {
  async fetchAllCampaigns(programId) {
    const url = `${URL}programs/${programId}/campaigns?limit=50`;
    return (await httpClient.get(url)).data.campaigns;
  },

  async saveCampaign(campaign) {
    const url = `${URL}/campaigns`;
    return (await httpClient.post(url, campaign)).data;
  },
  async getCampaignById(campaigId) {
    const url = `${URL}campaigns/${campaigId}`;
    return (await httpClient.get(url)).data.campaign;
  },
  async updateCampaign(campaign) {
    const url = `${URL}/campaigns/${campaign.id}`;
    return (await httpClient.patch(url, campaign)).data;
  },
  async deleteCampaign(ids) {
    return httpClient.delete(`${URL}/campaigns/${ids}`);
  },
};
