import programsRepository from "./programsRepository";
import campaignsRepository from "./campaignsRepository";
import emergenciesRepository from "./emergenciesRepository";
import donationsRepository from "./donationsRepository";
import faqsRepository from "./faqsRepository";

export const repositories = {
  programsRepository,
  campaignsRepository,
  emergenciesRepository,
  donationsRepository,
  faqsRepository

};

export const RepositoryFactory = {
  get: name => repositories[name]
};
