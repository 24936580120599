<template>
  <div @click="RouteTo(link)">
    <vs-card
      class="card"
      style="cursor: pointer;"
    >
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-align="right"
          vs-w="3"
        >
          <img
            class="mr-2"
            style="width: 60px; height: 60px;"
            :src="img"
            alt=""
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-align="right"
          vs-w="6"
        >
          <p class="card_title mt-5">
            {{ title }}
          </p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
        >
          <radial-progress-bar
            :diameter="75"
            :completed-steps="completePercentage"
            :total-steps="100"
            inner-stroke-color="#E6E9F0"
            :inner-stroke-width="3"
            :stroke-width="3"
            start-color="#48E18A"
            stop-color="#27B47B"
          >
            <p class="card_title ml-2">
              {{ completePercentage }}%
            </p>
          </radial-progress-bar>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';

export default {
  name: "TmCard",
  components: {
    RadialProgressBar
  },
  props: {
    title: {type: String, default: null},
    link: {type: String, default: null},
    img: {type: String, default: null},
    completePercentage: {type: Number, default: null},
  },
  methods: {
    RouteTo(x) {
      this.$router.push({path: x});
    }
  }
};
</script>

<style scoped>
.card {
  background-color: white;
  border-radius: 15px;
  width: 320px;
  height: 90px
}

.content p:first-child {
  font-weight: 700;
  font-size: 24px;
}

.content p:last-child {
  font-weight: 500;
  font-size: 16px;
}

.card_title {
  font-size: 16px;
  font-weight: 700;
  color: #5E445A;
}
</style>
