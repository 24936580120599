import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.donationsApiBaseUrl;

export default {
  async fetchAllEmergencies(programId) {
    const url = `${URL}programs/${programId}/emergencies?limit=50`;
    return (await httpClient.get(url)).data.emergencies;
  },

  async saveEmergency(emergency) {
    const url = `${URL}/emergencies`;
    return (await httpClient.post(url, emergency)).data;
  },
  async getEmergencyById(emergencyId) {
    const url = `${URL}/emergencies/${emergencyId}`;
    return (await httpClient.get(url)).data.emergency;
  },
  async updateEmergency(emergency) {
    const url = `${URL}/emergencies/${emergency.id}`;
    return (await httpClient.patch(url, emergency)).data;
  },
  async deleteEmergency(ids) {
    return httpClient.delete(`${URL}/emergencies/${ids}`);
  },
};
