import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.donationsApiBaseUrl}donations`;

export default {
  async fetchAllDonations(query) {
    const url = `${URL}${query}`;
    return (await httpClient.get(url)).data;
  },
  async deleteDonation(id) {
    const url = `${URL}/${id}`;
    return await httpClient.delete(url);
  },
  async fetchAllDonationsByProgram(query, programId) {
    const url = `${URL}${query}&filter=programId||$eq||${programId}`;
    return (await httpClient.get(url)).data;
  },
  async fetchAllDonationsByCampaign(query, campaignId) {
    const url = `${URL}${query}&filter=campaign_id||$eq||${campaignId}`;
    return (await httpClient.get(url)).data;
  },
  async fetchAllDonationsByEmergency(query, emergencyId) {
    const url = `${URL}${query}&filter=emergency_id||$eq||${emergencyId}`;
    return (await httpClient.get(url)).data;
  },
  exportDonationsToExcel() {
    const url = `${URL}/export-to-excel`;
    return httpClient.get(url, {responseType: 'arraybuffer'})
      .then(res => {
        let fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'eventUsers.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => console.log(error));
  }

};
