import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.donationsApiBaseUrl}programs`;

export default {
  async fetchAllPrograms() {
    const url = `${URL}?limit=50`;
    return (await httpClient.get(url)).data;
  },

  async saveProgram(program) {
    return (await httpClient.post(URL, program)).data;
  },
  async getProgramById(programId) {
    const url = `${URL}/${programId}`;
    return (await httpClient.get(url)).data.program;
  },
  async updateProgram(program) {
    const url = `${URL}/${program.id}`;
    return (await httpClient.patch(url, program)).data;
  },
  async deleteProgram(ids) {
    return httpClient.delete(`${URL}/${ids}`);
  },
  async getProgramHistory(programId) {
    const url = `${URL}/${programId}/history`;
    return (await httpClient.get(url)).data;
  }
};
