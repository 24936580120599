import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.donationsApiBaseUrl}faqs`;

export default {
  async fetchAllFaqs() {
    return (await httpClient.get(URL)).data.data;
  },

  async saveFaq(faq) {
    return (await httpClient.post(URL, faq)).data;
  },
  async updateFaq(faq) {
    const url = `${URL}/${faq.id}`;
    return (await httpClient.patch(url, faq)).data;
  },
  async deleteFaq(ids) {
    return httpClient.delete(`${URL}/${ids}`);
  }
};
